// bower:scss
@import "bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "global/_variables.scss";
@import "global/_mixins.scss";
@import "global/_layout.scss";

//settings:scss
@mixin drop-shadow($vertical-spread, $horizontal-spread) {
  box-shadow: 0 15px $vertical-spread rgba(50,50,93,.1), 0 5px $horizontal-spread rgba(0,0,0,.07);
  -webkit-box-shadow: 0 15px $vertical-spread rgba(50,50,93,.1), 0 5px $horizontal-spread rgba(0,0,0,.07);
  -moz-box-shadow: 0 15px $vertical-spread rgba(50,50,93,.1), 0 5px $horizontal-spread rgba(0,0,0,.07);
}
//endsettings

//layout:scss
body, html {
  font-family: 'Roboto Slab', serif;
}



body {
  overflow-x: hidden;
}

.container-fluid {
  margin: 0;
  padding: 0;
  position: relative;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.content-inner {
  max-width: 960px;
  margin: 0 auto;
  position: relative;
}

.white {
  position: relative;
  background: #fff;
  margin: 125px 0;

  h3, p {
    text-align: center;
  }
}
.white:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 125%;
  top: -50px;
  transform: skewY(-6deg);
  background: #fff;
  z-index: -1;
}

.off-white {
  position: relative;
  background: #f6f9fc;
  margin: 125px 0;

  h3, p {
    text-align: center;
  }
}
.off-white:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 125%;
  top: -50px;
  transform: skewY(-6deg);
  background: #f6f9fc;
  z-index: -1;
}

.background-image {
  background: #e6226f;
  position: absolute;
  z-index: -2;
  overflow: hidden;
  height: 135%;

  img {
    position: relative;
    max-width: 100%;
    top:0;
     z-index: -999;
     opacity: 0.2;
  }
}

.padding {
  padding: 1em;
}

.footer {
  z-index: 1;
  margin-bottom: 0 !important;
  position: relative;

  img {
    width: 100%;
  }

  .content-inner {
    padding: 3em 0;
    position: relative;

    ul {
      list-style: none;
    }

    li.title {
      margin-bottom: 5px;
      font-weight: 600;
      color: #8898aa;
    }

    li {
      a {
        font-size: 0.9rem;
        color: #8898aa;
      }
    }
  }

  .footer__tappi-legal {
    text-align: center;
    padding: 1rem 0;
    border-top: 1px solid #ccc;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;

      li {
        display: inline-block;
        margin: 0 10px;
        font-size: 0.9rem;

        a {
          color: #8898aa;
        }
      }
    }
  }
}
.footer.off-white:before {
  height: 50%;
}
//endlayout

// type:scss
p, li {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}
small {
  font-family: 'Open Sans', sans-serif;
}
p {
  margin: 15px 0;
}
// endtype

//hero:scss
header {
  text-align: center;
  position: relative;
  height: 650px;

  .inner {
    padding: 1rem;
  }

  #stripes {
    width: 100%;
    height: 110%;
    overflow: hidden;
    transform: skewY(5deg);
    transform-origin: 0;
    background: linear-gradient(150deg,#53f 15%,#52ecec 70%,#d6fa11 100%);
    position: absolute;
    top: -220px;
    z-index: -1;

    img {
      width: 100%;
      opacity: 0.2;
    }

    span {
      position: absolute;
    }

  }

  .logo-section {
    img {
      max-width: 100%;
    }
  }

  .hero-content {
    text-align: left;
    padding: 1em 0;
    padding-top: 2em;
    font-family: 'Arial Narrow Bold', Helvetica, sans-serif;

    h1 {
      font-size: 4.5rem;
      color: #fff;
      font-weight: 800;
    }

    h2 {
      color: #fff;
      opacity: 0.7;
      font-weight: 600;
    }
  }
}

.child-header {
  color: #fff;

  h1 {
    margin-top: 1em;

  }

  nav.main-nav {
    border-bottom: 1px solid rgba(#fff, 0.5);
  }
}
// endhero

//nav:scss
a.nav-link {
  color: #fff;

  img {
    width: 110px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.main-nav {
  justify-content: center;
  align-items: center;

  .logo {
    width: 110px;
  }
}

.cd-morph-dropdown {
	position: relative !important;
	height: 60px;
	background-color: #fcfcfc;
  z-index: 9999;

	&::before {
		/* never visible - used in JS to check mq */
		content: 'mobile';
		display: none;
	}

	.nav-trigger {
		/* menu icon - visible on small screens only */
		position: absolute;
		top: 0;
		right: 0;
		height: 60px;
		width: 60px;
		/* replace text with icon */
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		color: transparent;

		span, span::after, span::before {
			/* these are the 3 lines of the menu icon */
			position: absolute;
			background-color: #fff;
			height: 3px;
			width: 26px;
		}

		span {
			//@include center;
			transition: background-color .3s;
		}

		span::after, span::before {
			content: '';
			left: 0;
			transition: transform .3s;
		}

		span::before {
			transform: translateY(-9px);
		}

		span::after {
			transform: translateY(9px);
		}
	}

	&.nav-open .nav-trigger span {
		background-color: transparent;

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}

	.main-nav {
		display: none;
	}

	.morph-dropdown-wrapper {
		display: none;
		position: absolute;
    top: 0;
		left: 0;
		width: 100%;
		padding: 1.2em 5%;
		box-shadow: inset 0 1px 0 shade(#fcfcfc, 10%);
		background-color: #fcfcfc;
	}

	&.nav-open .morph-dropdown-wrapper {
		display: block;
	}

	.dropdown-list  > ul > li {
		margin-bottom: 0;
	}

	.label {
		display: block;
		font-size: 2.2rem;
	}

	.content li {
		@include clearfix;
	}

	.gallery .content li {
    padding-bottom: 1em;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1em !important;
	}

	.gallery .content a {
		display: block;

		&::before {
			/* icon on the left
			content: '';
			display: inline-block;
			float: left;
			height: 54px;
			width: 54px;
			margin-right: .6em;
			background: red;
			border-radius: 50%;
			transition: background .2s; */
		}

		span, em {
			display: block;
			line-height: 1.2;
		}

		em {
			font-size: 1rem;
			padding: .4em 0 .2em;
			color: $color-1;
      font-style: normal;

		}

		span {
			font-size: 0.9rem;
			color: shade($color-3, 35%);
		}

		&:hover::before {
			background-color: $color-1;
		}
	}
  .gallery .content a:hover {
    em {
      text-decoration: none;
    }
  }

	.gallery li:nth-of-type(1) a::before {
		background: #f4e58a url(../img/cd-gallery-icons.svg) no-repeat 0 0;
	}

	.gallery li:nth-of-type(2) a::before {
		background: #F4AF6D url(../img/cd-gallery-icons.svg) no-repeat -54px 0;
	}

	.gallery li:nth-of-type(3) a::before {
		background: #DB6356 url(../img/cd-gallery-icons.svg) no-repeat -108px 0;
	}

	.gallery li:nth-of-type(4) a::before {
		background: #8D4645 url(../img/cd-gallery-icons.svg) no-repeat -162px 0;
	}

	.links .content > ul > li {
		margin-top: 1em;
	}

	.links-list a,
	.btn {
		display: block;
		margin-left: 14px;
		font-size: 2.2rem;
		line-height: 1.6;

		&:hover {
			color: $color-1;
		}
	}

	.content h2 {
		color: shade($color-3, 35%);
		text-transform: uppercase;
		font-weight: bold;
		font-size: 1rem;
		margin: 20px 0 10px 14px;
	}

	@include MQ(L) {
		position: absolute;
		height: 80px;
		left: 0;
		top: 0;
		width: 100%;
		padding: 0;
		text-align: center;
		background-color: transparent;

		&::before {
			content: 'desktop';
		}

		.nav-trigger {
			display: none;
		}

		.main-nav {
			display: inline-block;
		}

		.main-nav > ul > li {
			display: inline-block;
			float: left;
		}

		.main-nav > ul > li > a {
			display: block;
			padding: 1em;
			height: 70px;
			color: $color-3;
			font-size: 1.2rem;
			@include font-smoothing;
			transition: opacity .2s;
		}

		&.is-dropdown-visible .main-nav > ul > li > a {
			/* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
			opacity: .6;
		}

		&.is-dropdown-visible .main-nav > ul > li.active > a {
			opacity: 1;
		}

		.morph-dropdown-wrapper {
			/* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
			display: block;
			top: 58px;
			/* overwrite mobile style */
			width: auto;
			padding: 0;
			box-shadow: none;
			background-color: transparent;
			/* Force Hardware acceleration */
			transform: translateZ(0);
			will-change: transform;
			transform: translateY(20px);
			transition: transform .3s;
      z-index: 9999;
		}

		&.is-dropdown-visible .morph-dropdown-wrapper {
			transform: translateY(0);
		}

		.dropdown-list {
			position: absolute;
			top: 0;
			left: 0;
			visibility: hidden;
			transform: translateZ(0);
			will-change: transform, width, height;
			transition: visibility .3s;
			box-shadow: 0 10px 20px rgba(#000, .08);

			.no-csstransitions & {
				display: none;
			}

			&::before {
				/* dropdown top triangle */
				content: '';
				position: absolute;
				bottom: 100%;
				@include center(x);
				height: 0;
				width: 0;
				border: 8px solid transparent;
				border-bottom-color: $color-3;
				opacity: 0;
				transition: opacity .3s;
			}

			> ul {
				position: relative;
				z-index: 1;
				height: 100%;
				width: 100%;
				overflow: hidden;
        list-style: none;
        padding: 0;
			}
		}

		&.is-dropdown-visible .dropdown-list {
			visibility: visible;
			transition: transform .3s, width .3s, height .3s;

			&::before {
				opacity: 1;
			}
		}

		.dropdown {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			transition: opacity .3s, visibility .3s;

			&.active {
				opacity: 1;
				visibility: visible;
			}

			&.move-left .content {
				transform: translateX(-100px);
			}

			&.move-right .content {
				transform: translateX(100px);
			}
		}

		.label {
			/* hide the label on bigger devices */
			display: none;
		}

		.content {
			padding: 2.2em 1.8em;
			transition: transform .3s;
			text-align: left;

			> ul {
				@include clearfix;
        padding: 0;
        list-style: none;
			}

			> ul > li {
				@include column(.48);
				margin-right: 4%;
				margin-top: 0;
        list-style: none;

				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
		}

		.gallery .content {
			/* you need to set a width for the .content elements because they have a position absolute */
			width: 510px;
			padding-bottom: .8em;

			li {
				margin-bottom: 1.8em;

        a {
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }
			}
		}

		.links .content > ul > li {
			margin-top: 0;
		}

		.links .content,
		.button .content {
			width: 390px;
		}

		.links-list a {
			font-size: 1rem;
			margin-left: 0;
		}

		.btn {
			display: block;
			width: 100%;
			height: 60px;
			margin: 1.5em 0 0;
			font-size: 1rem;
			text-align: center;
			color: $color-3;
			line-height: 60px;
			background: $color-2;
			@include font-smoothing;

			&:hover {
				background: $color-1;
				color: $color-3;
			}
		}

		.content h2 {
			font-size: 1rem;
			text-transform: none;
			font-weight: normal;
			color: $color-1;
			margin: 0 0 .6em;
		}

		.bg-layer {
			/* morph dropdown background */
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 1px;
			background: $color-3;
			opacity: 0;
			transition: opacity .3s;
			transform-origin: top left;
			transform: translateZ(0);
			will-change: transform;
			backface-visibility: hidden;
		}

		&.is-dropdown-visible .bg-layer {
			opacity: 1;
			transition: transform .3s, opacity .3s;
		}
	}
}

//endnav

//welcome section
.event-section {
  padding: 1em;
  @include drop-shadow(20px, 15px);

  h3 {
    text-align: center;
  }
}
//end welcome section

// box
.box {
  @include drop-shadow(15px, 5px);
  padding: 1.5em;
}
//endbox

//home-features
.home-feature {
  max-width:100%;

  .content-inner {
    padding-top: 5em;
  }
}
.home-feature.main-event {
  padding: 2rem 0;
  margin: 1rem 0;
  color: #fff;
}
//endhome-features

//connectdiscussion:scss
.connect__communities {
  img {
    opacity: .7;
  }
}
.connect__discussion {
  border-radius: 4px;
  padding: 0;
  background: #fff;
  @include drop-shadow(30px, 30px);

  .connect__discussion-box {
    border-bottom: 1px solid #ccc;
    padding: 1.5em;

    &::nth-last-of-type() {
      border: none;
    }
  }

  p {
    font-size: 0.9rem;
  }
}
//end connectdiscussion

//section-icons
.section-icon {
  position: absolute;
  z-index: 999;
  transition: all ease-in 0.3s;

  i {
    @include drop-shadow(25px, 25px);
    font-size: 10rem;
    -ms-transform: rotate(-12deg); /* IE 9 */
    -webkit-transform: rotate(-12deg); /* Chrome, Safari, Opera */
    transform: rotate(-12deg);
    z-index: 9999;
    transition: all ease-in 0.3s;
  }

   @media screen and (max-width: 1335px) {
    left: 40%;
    //right: 45%;
    top: -5px;

    i {
      font-size: 6rem;
      -ms-transform: rotate(-9deg); /* IE 9 */
      -webkit-transform: rotate(-9deg); /* Chrome, Safari, Opera */
      transform: rotate(-9deg);
      color: #e6226f;
      background: #fff;
      border-radius:5px;
    }
  }
}
.section-icon.connect {
  right: -150px;
  top: -80px;
  img {
    right: 0;
  }

  @media screen and (max-width: 1335px) {
    left: 40%;
    //right: 45%;
    top: -100px;

    img {
      width: 120px;
      -ms-transform: rotate(-9deg); /* IE 9 */
      -webkit-transform: rotate(-9deg); /* Chrome, Safari, Opera */
      transform: rotate(-9deg);
    }
  }
}
//end section icons
